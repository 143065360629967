<template>
    <div class="container-fluid">
        <div
            class="page-content"
            id="page-content"
        >
            <div class="header-greeting mb-5">
                <p class="p-0 m-0">Hi, {{ authUser.name }}</p>
                <h1 class="fw-bolder">Selamat datang di Recopedia</h1>
            </div>
            <div class="stats-card">
                <div class="row">
                    <div class="col-6 col-lg-3 pe-0">
                        <div class="card shadow">
                            <div class="card-body d-flex align-items-center">
                                <div class="icon-holder rounded-cirle">
                                    <i class="far fa-user"></i>
                                </div>
                                <div class="mt-2">
                                    <div class="stats fw-light">
                                        Total Pasien
                                    </div>
                                    <div class="num-count">
                                        {{ totalPasien }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-3 pe-0">
                        <div class="card shadow">
                            <div class="card-body d-flex align-items-center">
                                <div class="icon-holder rounded-cirle">
                                    <i class="far fa-clipboard"></i>
                                </div>
                                <div class="mt-2">
                                    <div class="stats fw-light">
                                        Total Resep
                                    </div>
                                    <div class="num-count">
                                        {{ totalResep }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-sm-12 col-lg-3 pe-0">
                        <div class="card shadow" id="abbot">
                            <a
                                href="https://id.abbott"
                                target="_blank"
                                class="stretched-link"
                            ></a>
                            <div class="card-body d-flex align-items-center">
                                <div class="icon-holder rounded-cirle">
                                    <i class="fas fa-headset"></i>
                                </div>
                                <div class="ms-3 mt-2">
                                    <div>
                                        Abbott Indonesia <br />
                                        Support
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- CHART Placeholde -->
            <div class="main-chart mt-5">
                <div class="row">
                    <div class="col-12 col-md-6 mb-3">
                        <div class="card p-3">
                            <div class="row align-items-center">
                                <div class="col-7">
                                    <date-range-picker
                                        ref="picker"
                                        :opens="opens"
                                        :locale-data="localData"
                                        v-model="dateRangeSusu"
                                        :showWeekNumbers="false"
                                        :showDropdowns="true"
                                        :linkedCalendars="false"
                                        @update="updateSusu"
                                    >
                                        <template style="width: 250px">
                                            {{ dateRangeSusu.startDate }} -
                                            {{ dateRangeSusu.endDate }}
                                        </template>
                                    </date-range-picker>
                                </div>
                                <div class="col-5">
                                    <div
                                        class="
                                            ms-lg-5
                                            text-center text-md-start
                                        "
                                    >
                                        <div class="stats">Jumlah Pasien</div>
                                        <h1 class="counter-card">
                                            {{ totalSusu }}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-12 mt-3">
                                    <h5 class="title-text">
                                        Merk Susu yang dibagikan
                                        <i
                                            v-if="loadChartSusu"
                                            class="fa fa-spinner fa-spin"
                                        ></i>
                                    </h5>
                                    <div
                                        class="chart-container"
                                        style="
                                            position: relative;
                                            height: auto;
                                            width: auto;
                                        "
                                    >
                                        <canvas id="chart_susu"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <div class="card p-3">
                            <div class="row align-items-center">
                                <div class="col-7">
                                    <date-range-picker
                                        ref="picker"
                                        :opens="opens"
                                        :locale-data="localData"
                                        v-model="dateRangeGender"
                                        :showWeekNumbers="false"
                                        :showDropdowns="true"
                                        :linkedCalendars="false"
                                        @update="updateGender"
                                    >
                                        <template style="width: 250px">
                                            {{ dateRangeGender.startDate }} -
                                            {{ dateRangeGender.endDate }}
                                        </template>
                                    </date-range-picker>
                                </div>
                                <div class="col-5">
                                    <div
                                        class="
                                            ms-lg-5
                                            text-center text-md-start
                                        "
                                    >
                                        <div class="stats">Jumlah Pasien</div>
                                        <h1 class="counter-card">
                                            {{ totalGender }}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-12 mt-3">
                                    <h5 class="title-text">
                                        Jenis Kelamin
                                        <i
                                            v-if="loadChartGender"
                                            class="fa fa-spinner fa-spin"
                                        ></i>
                                    </h5>
                                    <div
                                        class="chart-container"
                                        style="
                                            position: relative;
                                            height: auto;
                                            width: auto;
                                        "
                                    >
                                        <canvas id="chart_gender"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <div class="card p-3">
                            <div class="row align-items-center">
                                <div class="col-7">
                                    <date-range-picker
                                        ref="picker"
                                        :opens="opens"
                                        :locale-data="localData"
                                        v-model="dateRangeAge"
                                        :showWeekNumbers="false"
                                        :showDropdowns="true"
                                        :linkedCalendars="false"
                                        @update="updateAge"
                                    >
                                        <template style="width: 250px">
                                            {{ dateRangeAge.startDate }} -
                                            {{ dateRangeAge.endDate }}
                                        </template>
                                    </date-range-picker>
                                </div>
                                <div class="col-5">
                                    <div
                                        class="
                                            ms-lg-5
                                            text-center text-md-start
                                        "
                                    >
                                        <div class="stats">Jumlah Pasien</div>
                                        <h1 class="counter-card">
                                            {{ totalAge }}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-12 mt-3">
                                    <h5 class="title-text">
                                        Rentang Usia
                                        <i
                                            v-if="loadChartAge"
                                            class="fa fa-spinner fa-spin"
                                        ></i>
                                    </h5>
                                    <div
                                        class="chart-container"
                                        style="
                                            position: relative;
                                            height: auto;
                                            width: auto;
                                        "
                                    >
                                        <canvas id="chart_age"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END CHART Placeholde -->
        </div>
    </div>
</template>

<script>
// import { getError } from "@/utils/helpers";
import { mapGetters } from 'vuex';
import AuthService from '@/services/AuthService';
import DateRangePicker from 'vue2-daterange-picker';
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import {
    Chart,
    DoughnutController,
    ArcElement,
    Title,
    Legend,
    Tooltip,
} from 'chart.js';
Chart.register(DoughnutController, ArcElement, Title, Legend, Tooltip);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
        animateRotate: false,
    },
    plugins: {
        legend: {
            display: true,
            position: 'right',
            labels: {
                usePointStyle: true,
                pointStyle: 'circle',
                padding: 30,
            },
        },
        maintainAspectRatio: false,
        labels: {
            font: {
                size: 22,
            },
        },
    },
};

export default {
    name: 'Dashboard',
    components: { DateRangePicker },
    data() {
        let endDate = new Date();
        endDate.setDate(endDate.getDate() + 6);
        return {
            options,
            chartIdGender: 'gender_chart',
            loadChartGender: false,
            loadChartAge: false,
            loadChartSusu: false,
            chartDataSusu: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: ['#0075FF', '#4318FF', '#E9EDF7'],
                        data: [],
                        hoverOffset: 10,
                    },
                ],
            },
            chartDataAge: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: ['#0075FF', '#4318FF', '#E9EDF7'],
                        data: [],
                        hoverOffset: 10,
                    },
                ],
            },
            chartDataGender: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: ['#0075FF', '#4318FF', '#E9EDF7'],
                        data: [],
                        hoverOffset: 10,
                    },
                ],
            },
            showDropdowns: true,
            labelGender: [],
            dataGender: [],
            totalPasien: 0,
            totalResep: 0,
            totalSusu: 0,
            totalGender: 0,
            totalAge: 0,
            opens: 'center',
            localData: {
                format: 'mm/dd/yyyy',
                separator: ' - ',
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                monthNames: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                ],
                firstDay: 1,
            },
            dateRangeSusu: {
                startDate: new Date(),
                endDate: endDate,
            },
            dateRangeGender: {
                startDate: new Date(),
                endDate: endDate,
            },
            dateRangeAge: {
                startDate: new Date(),
                endDate: endDate,
            },
            startDateGender: '',
            endDateGender: '',
            startDateSusu: '',
            endDateSusu: '',
            startDateAge: '',
            endDateAge: '',
        };
    },
    created() {
        this.$store.dispatch('auth/setMenuActive', { value: 'dashboard' });
        document.title = 'Dashboard'
    },
    beforeMount() {},
    async mounted() {
        const c_date = document.getElementsByClassName('vue-daterange-picker');
        for (var i = 0; i < c_date.length; i++) {
            c_date[i].className += ' shadow';
        }

        /** Set First Date Gender */
        const startGender = new Date(this.dateRangeGender.startDate);
        const endGender = new Date(this.dateRangeGender.endDate);
        this.startDateGender =
            startGender.getFullYear() +
            '-' +
            ('0' + (startGender.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + startGender.getDate()).slice(-2);
        this.endDateGender =
            endGender.getFullYear() +
            '-' +
            ('0' + (endGender.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + endGender.getDate()).slice(-2);

        const startSusu = new Date(this.dateRangeSusu.startDate);
        const endSusu = new Date(this.dateRangeSusu.endDate);
        this.startDateSusu =
            startSusu.getFullYear() +
            '-' +
            ('0' + (startSusu.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + startSusu.getDate()).slice(-2);
        this.endDateSusu =
            endSusu.getFullYear() +
            '-' +
            ('0' + (endSusu.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + endSusu.getDate()).slice(-2);

        const startAge = new Date(this.dateRangeAge.startDate);
        const endAge = new Date(this.dateRangeAge.endDate);
        this.startDateAge =
            startAge.getFullYear() +
            '-' +
            ('0' + (startAge.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + startAge.getDate()).slice(-2);
        this.endDateAge =
            endAge.getFullYear() +
            '-' +
            ('0' + (endAge.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + endAge.getDate()).slice(-2);

        this.onLoadDashboard();
    },
    computed: {
        ...mapGetters('auth', ['authUser']),
        computeMarginLeft: function () {
            const width = window.innerWidth;
            if (width >= 768) {
                return '280px';
            }

            if (width < 768) {
                return '80px';
            }

            return '0px';
        },
    },
    methods: {
        updateChart() {
            this.$refs.gender_chart.update();
        },
        updateSusu() {
            const start = new Date(this.dateRangeSusu.startDate);
            const startFormat =
                start.getFullYear() +
                '-' +
                ('0' + (start.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + start.getDate()).slice(-2);

            const end = new Date(this.dateRangeSusu.endDate);
            const endtFormat =
                end.getFullYear() +
                '-' +
                ('0' + (end.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + end.getDate()).slice(-2);

            this.startDateSusu = startFormat;
            this.endDateSusu = endtFormat;
            this.loadChartSusu = true;
            this.onLoadDashboard();
        },
        updateGender() {
            const start = new Date(this.dateRangeGender.startDate);
            const startFormat =
                start.getFullYear() +
                '-' +
                ('0' + (start.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + start.getDate()).slice(-2);

            const end = new Date(this.dateRangeGender.endDate);
            const endtFormat =
                end.getFullYear() +
                '-' +
                ('0' + (end.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + end.getDate()).slice(-2);

            this.startDateGender = startFormat;
            this.endDateGender = endtFormat;
            this.loadChartGender = true;
            this.onLoadDashboard();
        },
        updateAge() {
            const start = new Date(this.dateRangeAge.startDate);
            const startFormat =
                start.getFullYear() +
                '-' +
                ('0' + (start.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + start.getDate()).slice(-2);

            const end = new Date(this.dateRangeAge.endDate);
            const endtFormat =
                end.getFullYear() +
                '-' +
                ('0' + (end.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + end.getDate()).slice(-2);

            this.startDateAge = startFormat;
            this.endDateAge = endtFormat;
            this.loadChartAge = true;
            this.onLoadDashboard();
        },
        async onLoadDashboard() {
            const payload = {
                'date_1[gender]': this.startDateGender,
                'date_2[gender]': this.endDateGender,
                'date_1[susu]': this.startDateSusu,
                'date_2[susu]': this.endDateSusu,
                'date_1[age]': this.startDateAge,
                'date_2[age]': this.endDateAge,
            };
            await AuthService.getDashboard(payload).then((response) =>
                this.onResponse(response)
            );
            //.catch((error) => getError(error))
        },
        onResponse(response) {
            if (response.data.result == 'success') {
                if (
                    this.loadChartGender == false &&
                    this.loadChartAge == false &&
                    this.loadChartAge == false
                ) {
                    this.totalPasien = response.data.total_pasien;
                    this.totalResep = response.data.total_resep;
                    this.totalSusu = response.data.chart_susu.total;
                    this.totalGender = response.data.chart_gender.total;
                    this.totalAge = response.data.chart_age.total;

                    this.chartDataSusu.labels = response.data.chart_susu.labels;
                    this.chartDataSusu.datasets[0].data =
                        response.data.chart_susu.data;

                    this.chartDataGender.labels =
                        response.data.chart_gender.labels;
                    this.chartDataGender.datasets[0].data =
                        response.data.chart_gender.data;

                    this.chartDataAge.labels = response.data.chart_age.labels;
                    this.chartDataAge.datasets[0].data =
                        response.data.chart_age.data;

                    this.chartGenderLoad();
                    this.chartSusuLoad();
                    this.chartAgeLoad();
                }

                if (this.loadChartGender == true) {
                    this.loadChartGender = false;
                    this.totalGender = response.data.chart_gender.total;
                    this.chartDataGender.labels =
                        response.data.chart_gender.labels;
                    this.chartDataGender.datasets[0].data =
                        response.data.chart_gender.data;
                    this.chartGenderLoad();
                }

                if (this.loadChartSusu == true) {
                    this.loadChartSusu = false;
                    this.totalSusu = response.data.chart_susu.total;
                    this.chartDataSusu.labels = response.data.chart_susu.labels;
                    this.chartDataSusu.datasets[0].data =
                        response.data.chart_susu.data;
                    this.chartSusuLoad();
                }

                if (this.loadChartAge == true) {
                    this.loadChartAge = false;
                    this.totalSusu = response.data.chart_age.total;
                    this.chartDataAge.labels = response.data.chart_age.labels;
                    this.chartDataAge.datasets[0].data =
                        response.data.chart_age.data;
                    this.chartAgeLoad();
                }
            }
        },
        chartGenderLoad() {
            var context = document.getElementById('chart_gender');
            if (window.myChartGender) {
                window.myChartGender.destroy();
            }
            window.myChartGender = new Chart(context, {
                type: 'doughnut',
                data: this.chartDataGender,
                options: this.options,
            });
            window.myChartGender.update();
        },
        chartSusuLoad() {
            var context = document.getElementById('chart_susu');
            if (window.myChartSusu) {
                window.myChartSusu.destroy();
            }
            window.myChartSusu = new Chart(context, {
                type: 'doughnut',
                data: this.chartDataSusu,
                options: this.options,
            });
            window.myChartSusu.update();
        },
        chartAgeLoad() {
            var context = document.getElementById('chart_age');
            if (window.myChartAge) {
                window.myChartAge.destroy();
            }
            window.myChartAge = new Chart(context, {
                type: 'doughnut',
                data: this.chartDataAge,
                options: this.options,
            });
            window.myChartAge.update();
        },
    },
};
</script>
